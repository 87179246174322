$(document).ready(function() {

	let menuState = 0;

	/* =====  MOBILE MENU TOGGLE  ====== */
	$('.offCanvasToggler').click(function(event) {
		event.preventDefault();

		// if (menuState == 0) {
		// 	menuState = 1;
		// 	$('header').css('z-index', '0');
		// } else {
		// 	menuState = 0;
		// 	$('header').css('z-index', '1000');
		// }

		$('.off-canvas-nav').toggleClass('active');
		$('.fullscreen-overlay').toggleClass('active');
		$('html, body').toggleClass('no-scrolling');
	});

	/*======  SLIDERS  ======*/

	/*----------  HOME HEADER SLIDER  ----------*/

	$('.home-header .slides').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		dots: false
	});

	/*----------  HOME TROEVEN SLIDER  ----------*/

	$('.troeven-slider').slick({
		slidesToShow: 3,
		slidesToScroll: 3,
		arrows: false,
		dots: false,
		responsive: [
    {
      breakpoint: 991.98,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
				autoplay: true,
				autoplaySpeed: 5000
      }
    },
    {
      breakpoint: 575.98,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
				autoplay: true,
				autoplaySpeed: 5000
      }
    }
  ]
	});

	$('.getuigenis-slider').slick({
		slidesToShow: 2,
		slidesToScroll: 2,
		arrows: false,
		dots: false,
		responsive: [
    {
      breakpoint: 991.98,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
				autoplay: true,
				autoplaySpeed: 5000
      }
    },
    {
      breakpoint: 575.98,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
				autoplay: true,
				autoplaySpeed: 5000
      }
    }
  ]
	});

	/*======  GRIDS  ======*/

	/*----------  NIEUWS GRID  ----------*/

	$('.nieuws-grid').isotope({
		itemSelector: '.grid-item',
		percentPosition: true,
		masonry: {
			columnWidth: '.grid-sizer',
			gutter: 30
		}
	});

	/*======  NEWSLETTER  ======*/

	$('.newsletter').each(function(i, el){
		$(this).on('submit', 'form', function(e){
			e.preventDefault();
			var $el = $(this).closest('.newsletter');
			var $form = $(this);
			var isFooter = $el.hasClass('newsletter-footer');
			$.ajax({
				url: $form.attr('action'),
	    		data: $form.serialize(),
	    		dataType: 'json',
	    		type: 'post',
				success: function(data){
					$('.text-danger', $form).remove();
					if(data.status == 'success'){
						var h = '';
						if(isFooter){ h = 'h5'; }else{ h = 'h3'; }
						var $message = $('<'+h+'>'+data.message+'</'+h+'>');
						$message.hide();
						$form.hide().after($message);
						$message.fadeIn(400);
		            }else if(data.status == 'error'){
		            	var $message = $('<p class="text-danger small-text">'+data.message+'</p>');
		            	$message.hide();
		            	$form.append($message);
		            	$message.fadeIn(400);
		            }
				}
		    });
		});
	});



	/**
   *
   * SCROLLREVEAL Functies
   * -- Helper classes voor ScrollReveal --
   *
   */

  /*----------  Reveal variables  ----------*/
  var revealDuration = 850;
  var revealEasing = 'cubic-bezier(0.645, 0.045, 0.355, 1.000)';
  var revealDelayBase = 150;

  /*----------  Reveals van bovenaan  ----------*/
  ScrollReveal().reveal('.reveal-up', {
    duration: revealDuration,
    distance: '60px',
    origin: 'top',
    easing: revealEasing,
    mobile: false
  });

  /*----------  Reveals van onderaan  ----------*/
  ScrollReveal().reveal('.reveal-down', {
    duration: revealDuration,
    distance: '60px',
    origin: 'bottom',
    easing: revealEasing,
    mobile: false
  });

  /*----------  Reveals van links  ----------*/
  ScrollReveal().reveal('.reveal-left', {
    duration: revealDuration,
    distance: '150px',
    origin: 'left',
    easing: revealEasing,
    mobile: false
  });

  ScrollReveal().reveal('.reveal-left-far', {
    duration: revealDuration,
    distance: '300px',
    origin: 'left',
    easing: revealEasing,
    mobile: false
  });

  /*----------  Reveals van rechts  ----------*/
  ScrollReveal().reveal('.reveal-right', {
    duration: revealDuration,
    distance: '150px',
    origin: 'right',
    easing: revealEasing,
    mobile: false
  });

  ScrollReveal().reveal('.reveal-right-far', {
    duration: revealDuration,
    distance: '300px',
    origin: 'right',
    easing: revealEasing,
    mobile: false
  });

  /*----------  Reveals voor cards  ----------*/
  for (var i = 1; i < 100; i++) {
    var delayAmount = revealDelayBase * i;

    ScrollReveal().reveal('.reveal-card-' + i, {
      duration: revealDuration,
      distance: '60px',
      origin: 'bottom',
      easing: revealEasing,
      mobile: false,
      delay: delayAmount
    });
  }


});

$(document).scroll(function(event) {
	if ($(window).scrollTop() <= 200) {
		$('.navbar').removeClass('scrolled');
	} else {
		$('.navbar').addClass('scrolled');
	}
});
